@value visuallyHidden, resetButton from '../../../../../ring-globals.css';
@value unit: var(--ring-unit);

.outerContainer {
  --tc-tail-width: calc(unit * 10);

  overflow: hidden;

  margin: calc(unit * 2) calc(unit * -4) 0;

  padding: 0 calc(unit * 4);
}

.container {
  position: relative;

  margin-top: calc(unit * 7);
}

.pannable {
  cursor: all-scroll;
}

.previewContainer {
  margin: 0 calc(unit * -4);
  padding: 0 calc(unit * 4);

  cursor: pointer;

  background-color: var(--ring-sidebar-background-color);

  box-shadow:
    0 1px 0 0 var(--ring-line-color) inset,
    0 -1px 0 0 var(--ring-line-color) inset;
}

.preview {
  position: relative;
}

.invisible {
  visibility: hidden;
}

.victoryContainer > svg {
  overflow: visible;
}

.barWrapper {
  position: absolute;

  display: flex;
}

.barDropdown {
  display: inline-flex;
}

.bar {
  composes: resetButton;

  position: relative;

  overflow: hidden;

  box-sizing: border-box;

  width: 100%;
  height: calc(unit * 2.5);
  padding-bottom: 2px;

  cursor: pointer;

  transition: opacity var(--ring-fast-ease), box-shadow var(--ring-fast-ease);

  border-radius: var(--ring-border-radius);
  outline: none;

  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    content: '';

    transition: border-color var(--ring-fast-ease);

    border: 2px solid transparent;

    border-radius: var(--ring-border-radius);
  }

  &:focus{
    transition: none;

    box-shadow: 0 0 0 1px var(--ring-border-hover-color), 0 0 0 1px var(--ring-border-hover-color) inset;

    &::after {
      transition: none;

      border-width: 1px;

      border-style: solid;

      border-color: var(--ring-border-hover-color);
    }
  }
}

.fade {
  opacity: 0.5;
}

.selected {
  transition: none;
}

.progress {
  position: absolute;
  top: 0;

  overflow: hidden;

  height: 100%;
}

.yetToRun {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;

  box-sizing: border-box;

  background-color: var(--ring-sidebar-background-color);
}

.queued .yetToRun {
  border: 1px solid var(--ring-borders-color);
  border-top-right-radius: var(--ring-border-radius);
  border-bottom-right-radius: var(--ring-border-radius);
}

.success {
  & .progress {
    background-color: var(--tc-success-progress-color);
  }

  &::after {
    border-color: var(--tc-success-progress-color);
  }
}

.failed {
  & .progress {
    background-color: var(--ring-error-color);
  }

  &::after {
    border-color: var(--ring-error-color);
  }
}

.canceled {
  & .progress {
    background-color: var(--ring-line-color);
  }

  &::after {
    border-color: var(--ring-line-color);
  }
}

.failedToStart {
  color: var(--ring-error-color);
}

.incomplete {
  background-color: transparent;

  &::after {
    border: 1px solid var(--ring-borders-color);
  }
}

.queued {
  background-color: transparent;

  &::after {
    border: 1px dashed var(--ring-borders-color);
  }
}

.name {
  position: absolute;
  top: 2px;

  display: flex;
  align-items: baseline;

  box-sizing: border-box;
  max-width: 100%;

  height: 15px;

  padding: 0 unit;

  border-radius: var(--ring-border-radius);

  font-size: var(--ring-font-size-smaller);
  line-height: 15px;
}

.nameInner {
  overflow: hidden;

  min-width: 0;

  white-space: nowrap;
  text-overflow: ellipsis;
}

.unknownFinish {
  overflow: visible;

  &,
  &::after {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  &::after {
    left: 0;

    width: var(--tc-bar-width);

    border-right: none;
  }

  &:focus{
    border-top-right-radius: var(--ring-border-radius);
    border-bottom-right-radius: var(--ring-border-radius);

    &::after {
      border-right: none;
    }
  }

  &::before {
    position: absolute;
    top: 0;
    bottom: 0;
    left: var(--tc-bar-width);

    box-sizing: border-box;

    width: var(--tc-tail-width);

    content: '';

    border-top: 1px solid var(--ring-borders-color);
    border-bottom: 1px solid var(--ring-borders-color);
    background-color: var(--ring-sidebar-background-color);
    mask-image: linear-gradient(to right, black, transparent);
  }

  &:focus::before{
    border: 1px solid var(--ring-border-hover-color);
    border-right: none;
    mask-image: none;
  }

  & .name {
    max-width: initial;
  }

  & > .name {
    position: relative;
    top: 0;
  }
}

.queued::before {
  border-left: 1px solid var(--ring-borders-color);
}

.unknownStart {
  &::after {
    content: none;
  }

  &::before {
    left: 0;

    width: var(--tc-tail-width);

    border-top-style: dashed;
    border-bottom-style: dashed;
    border-left-style: dashed;
    border-top-left-radius: var(--ring-border-radius);
    border-bottom-left-radius: var(--ring-border-radius);
    background-color: var(--ring-content-background-color);
  }
}

@value patternSize: unit;

@keyframes bar-move {
  from {
    transform: translateY(0);
  }

  to {
    transform: translateX(calc(patternSize * -1));
  }
}

.running .progress::before,
.running.progress::before {
  position: absolute;

  display: block;

  width: calc(100% + patternSize);

  height: 100%;

  content: '';
  animation: bar-move 1s linear infinite;

  background-repeat: repeat;
  background-position: left;
  background-size: patternSize patternSize;
}

.success .progress::before,
.success.progress::before {
  background-color: var(--tc-success-background-color);
  background-image:
    linear-gradient(
      135deg,
      var(--ring-icon-success-color),
      var(--ring-icon-success-color) 3.75%,
      transparent 3.75%,
      transparent 46.25%,
      var(--ring-icon-success-color) 46.25%,
      var(--ring-icon-success-color) 53.75%,
      transparent 53.75%,
      transparent 96.25%,
      var(--ring-icon-success-color) 96.25%,
      var(--ring-icon-success-color)
    );
}

.failed .progress::before,
.failed.progress::before {
  background-color: var(--ring-icon-error-color);
  background-image:
    linear-gradient(
      45deg,
      var(--tc-error-background-color),
      var(--tc-error-background-color) 3.75%,
      transparent 3.75%,
      transparent 46.25%,
      var(--tc-error-background-color) 46.25%,
      var(--tc-error-background-color) 53.75%,
      transparent 53.75%,
      transparent 96.25%,
      var(--tc-error-background-color) 96.25%,
      var(--tc-error-background-color)
    );
}

.buildsPopup {
  max-height: 45vh;
}

.buildInList {
  max-width: calc(unit * 58);
  margin: 0;

  transition: background-color var(--ring-fast-ease);

  &:hover {
    transition: none;

    background-color: var(--ring-hover-background-color);
  }

  &:not(:first-child) {
    border-top: 1px solid var(--ring-line-color);
  }
}

.nameHidden {
  composes: visuallyHidden;
}

.failed .progress > .name,
.failed.progress > .name {
  color: var(--ring-white-text-color);
}

.success .name::before {
  background-color: var(--tc-success-background-color);
}

.failed .name::before {
  background-color: var(--ring-icon-error-color);
}

/* stylelint-disable-next-line selector-max-specificity  */
.running > .name::before {
  position: absolute;
  top: 1px;
  right: 0;
  bottom: -1px;
  left: 0;

  content: '';

  opacity: 0.6;
}

.gap {
  position: absolute;
  top: calc(unit * -1);
  bottom: 0;

  width: 18px;

  color: var(--ring-sidebar-background-color);

  background:
    linear-gradient(135deg, common-gradient) -9px -9px,
    linear-gradient(45deg, common-gradient) 9px 9px,
    linear-gradient(-135deg, common-gradient) 9px 0,
    linear-gradient(-45deg, common-gradient) 9px 0;
  background-size: 18px 18px;

  @value common-gradient: transparent 75%, var(--ring-line-color) 75%, currentColor 79%;
}

.previewGap {
  top: 1px;
  bottom: 1px;

  color: var(--ring-content-background-color);
}

.brush {
  fill: var(--ring-content-background-color);
}

.brushBorder {
  position: absolute;
  top: 0;
  bottom: 0;

  pointer-events: none;

  box-shadow: 0 0 0 1px var(--ring-border-hover-color) inset;
}

.previewBarSuccess {
  fill: var(--tc-success-progress-color);
}

.previewBarFailed {
  fill: var(--ring-error-color);
}

.previewBarCanceled {
  fill: var(--ring-icon-secondary-color);
}

.previewBarQueued {
  fill: var(--ring-line-color);
}

.previewBarFocused {
  fill: var(--ring-main-color);
}

.previewBarFade {
  fill-opacity: 0.5;
}

.queueInfo {
  font-size: var(--ring-font-size);
  line-height: calc(unit * 3);
}

.started {
  display: inline-block;

  margin-left: unit;

  color: var(--ring-secondary-color);

  font-size: var(--ring-font-size-smaller);
}

.hourglass {
  height: auto;

  white-space: nowrap;

  line-height: normal;

  &:not(:hover) {
    color: var(--ring-text-color);
  }
}

.statusIcon {
  margin: -2px 2px 0 -2px;

  line-height: normal;
}

.statusIcon.statusIcon svg {
  vertical-align: -4px;
}

.hourglassIcon {
  composes: statusIcon;
}

.canceledIcon {
  composes: statusIcon;
}

.failedToStartIcon {
  composes: statusIcon;
}

.name .hourglassIcon {
  color: var(--ring-icon-color);
}

.name .canceledIcon {
  color: var(--ring-icon-secondary-color);
}

.name .failedToStartIcon {
  color: var(--ring-icon-error-color);
}

.progress .failedToStartIcon {
  color: var(--ring-white-text-color);
}
